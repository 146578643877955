import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { DEFAULT_STATE } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
export class GeneralApiService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  // MENU
  getMenu(): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/menu/getMenu`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SUB-MENU
  getStateMenu(state: string): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/menu/getMenu?state=${
          state ? state : DEFAULT_STATE.id
        }`
      )
      .pipe(
        map((response: any) => response?.state_menu || null),
        catchError(this.apiService.handleError())
      );
  }

  // MENU
  getConfig(): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/menu/getConfigs`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SEARCH
  getSearch(data: any) {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/menu/search`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // PAGES
  getPage(slug: string): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/page/${slug}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  getPageFormated(slug: string): Observable<any> {
    return this.http.get(`${environment.APP_ENDPOINT}/api/page/${slug}`).pipe(
      map((data: any) => {
        return { title: data?.title, body: data?.sections[0]?.body };
      }),
      catchError(this.apiService.handleError())
    );
  }

  //FORM
  createFormComment(data: any) {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/createFormComment`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  //TAX
  getTax(tax: string): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/product/getTax/${tax}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  //SEND FEEDBACK
  sendMedicationFeedback(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/sendHowAreYouDoing`, data)
      .pipe(catchError(this.apiService.handleError()));
  }
}
